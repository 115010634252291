.jappu { max-width: 700px; }
.rangi { color: #fff; font-size: 15px; padding: 2px; }
.hriday {     border: none;
    color: white;
    cursor: pointer;
    margin-top: 12px;
    font-weight: 500;
    font-size: 15px!important;
    transition: 0.5s;
    border-radius: 5px;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    padding: 12px 24px 12px;
    text-transform: capitalize;
}